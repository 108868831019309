import { configureStore } from "@reduxjs/toolkit";
import tracking from "../features/tracking";
import wizard from "../features/wizard";

export const store = configureStore({
  reducer: {
    tracking,
    wizard,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
