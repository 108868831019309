import { createSlice } from "@reduxjs/toolkit";

export enum steps {
  Start,
  Tracking,
}

export interface WizardState {
  step: steps;
}

const initialState: WizardState = {
  step: steps.Start,
};

const wizardSlice = createSlice({
  name: "wizard",
  initialState,
  reducers: {
    nextStep(state) {
      state.step++;
    },
  },
});

export const { nextStep } = wizardSlice.actions;
export default wizardSlice.reducer;
