export interface GeoTrackerConfig {
  enableHighAccuracy: boolean;
  timeout: number;
  maximumAge: number;
}

export interface LocationTransmitterConfig {
  txInterval: number;
}

interface OSMConfig {
  tileUrl: string;
  tileUrlSubdomains: string[];
}

export interface AppConfig {
  geoTracker: GeoTrackerConfig;
  locationTransmitter: LocationTransmitterConfig;
  osm: OSMConfig;
  servers: { hostname: string }[];
}

export const config: AppConfig = {
  geoTracker: {
    enableHighAccuracy: true,
    timeout: 6000,
    maximumAge: 0,
  },
  locationTransmitter: {
    txInterval: 2000,
  },
  osm: {
    tileUrl:
      "https://osmtiles{s}/hot/{z}/{x}/{y}.webp?token=ab7df759-6dcf-427f-957e-0865735e96f7",
    tileUrlSubdomains: [
      "1.staging.app-notruf.de",
      "2.staging.app-notruf.eu",
      "3.staging.app-notruf.de",
      "4.staging.app-notruf.eu",
    ],
  },
  servers: [
    { hostname: "1.staging.app-notruf.de" },
    { hostname: "2.staging.app-notruf.eu" },
    { hostname: "3.staging.app-notruf.de" },
    { hostname: "4.staging.app-notruf.eu" },
  ],
};
