import { FC } from "react";
import styled from "styled-components";
import { Text } from "../components/Typography";
import Spinner from "../components/Spinner";
import logo from "../assets/logo.svg";

const LoadingAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 40px;
  flex: 1 1 100%;
  height: 100%;
`;

const LoadingApp: FC = () => {
  return (
    <LoadingAppContainer>
      <img
        src={logo}
        style={{
          marginTop: 19,
          marginBottom: 16,
          maxWidth: 200,
        }}
      />
      <Spinner size={80} alt="Laden" />
      <Text>Die Anwendung wird geladen</Text>
    </LoadingAppContainer>
  );
};

export default LoadingApp;
