import { useState, useEffect, FC } from "react";
import styled from "styled-components";
import { useAppSelector } from "../app/hooks";
import { Heading } from "../components/Typography";
import LoadingApp from "../screens/LoadingApp";
import UnknownCall from "../screens/UnknownCall";
import ConnectionError from "../screens/ConnectionError";
import ConnectScreen from "../screens/Connect";
import { steps } from "../features/wizard";
import { useClient, CallStatus, CallKinds } from "../context/Client";

import MapScreen from "../screens/Map";
import useGeoTracker from "../hooks/geoTracker";

// Route parameters
export interface MainViewRouteParams {
  id: string;
}

const ComponentForStep: FC<{ step: steps; psapName: string }> = ({
  step,
  psapName,
}) => {
  switch (step) {
    case steps.Start:
      return <ConnectScreen psapName={psapName} />;
    case steps.Tracking:
      return <MapScreen psapName={psapName} />;
  }
};

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const MainView: FC = () => {
  const { loading, connected, getCallStatus } = useClient();
  const [callStatus, setCallStatus] = useState<CallStatus | undefined>(
    undefined
  );
  const tracking = useAppSelector((state) => state.tracking);
  const wizard = useAppSelector((state) => state.wizard);

  useEffect(() => {
    getCallStatus().then(setCallStatus);
  }, []);

  useGeoTracker();

  const step = wizard.step;

  if (loading || !callStatus || connected === undefined) {
    return (
      <Main>
        <LoadingApp />
      </Main>
    );
  }

  if (!connected) {
    return (
      <Main>
        <ConnectionError />
      </Main>
    );
  }

  if (callStatus.kind === CallKinds.Unknown) {
    return (
      <Main>
        <UnknownCall />
      </Main>
    );
  }

  return (
    <>
      {tracking.error !== null ? (
        <Heading level={5}>{tracking.errorMessage}</Heading>
      ) : null}
      <Main>
        <ComponentForStep step={step} psapName={callStatus.psapName} />
      </Main>
    </>
  );
};
