import { FC } from "react";
import Error from "../components/Error";
import noConnection from "../assets/NoConnection.svg";

const LoadingApp: FC = () => (
  <Error
    message={
      "Es konnte keine Verbindung zu den Servern hergestellt werden.\n\nBitte überprüfen Sie Ihre Internetverbindung."
    }
    image={noConnection}
  />
);

export default LoadingApp;
