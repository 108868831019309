import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Position {
  timestamp: number;
  coords: {
    accuracy: number;
    latitude: number;
    longitude: number;
    speed: number | null;
  };
}

export enum TrackingErrorType {
  UnsupportedDevice,
  PermissionDenied,
  PositionUnavailable,
  Timeout,
  InvalidCallId,
  UnknownTransmissionFailure,
}

const getErrorMessage = (error: TrackingErrorType): string =>
  [
    "Ihr Gerät oder Browser unterstützt keine Standortermittlung.",
    "Bitte erlauben Sie die Standortermittlung.",
    "Standort kann nicht ermittelt werden.",
    "Timeout",
    "Diese Ortungs-URL ist nicht mehr gültig.",
    "Standort-Übermittlung nicht möglich.",
  ][error]; //TODO: maybe not an array??? This feels unstable...

export interface TrackingState {
  started: boolean;
  error: TrackingErrorType | null;
  errorMessage: string | null;
  timestamp: number | null;
  accuracy: number | null;
  latitude: number | null;
  longitude: number | null;
  speed: number | null;
}

const initialState: TrackingState = {
  started: false,
  error: null,
  errorMessage: null,
  timestamp: null,
  accuracy: null,
  latitude: null,
  longitude: null,
  speed: null,
};

const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    start: (state: TrackingState) => ({
      ...state,
      started: true,
    }),

    updatePosition: (
      state: TrackingState,
      { payload }: PayloadAction<Position>
    ) => {
      console.log({ coords: payload.coords });
      return {
        ...state,
        timestamp: payload.timestamp,
        latitude: payload.coords.latitude,
        longitude: payload.coords.longitude,
        accuracy: payload.coords.accuracy,
        speed: payload.coords.speed,
      };
    },

    updateError: (
      state: TrackingState,
      { payload }: PayloadAction<{ error: TrackingErrorType }>
    ) => ({
      ...state,
      error: payload.error,
      errorMessage: getErrorMessage(payload.error),
    }),
  },
});

export const { updateError, updatePosition, start } = trackingSlice.actions;
export default trackingSlice.reducer;
