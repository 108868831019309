import "core-js/es/map";
import "core-js/es/set";
import "core-js/features/promise";
import "whatwg-fetch";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Switch, Route } from "react-router";
import { HashRouter } from "react-router-dom";
import { MainView } from "./views/main";
import { store } from "./app/store";
import { ClientProvider } from "./context/Client";

import "./style.css";
ReactDOM.render(
  <ClientProvider>
    <Provider store={store}>
      <HashRouter>
        <Switch>
          <Route path="/:id?" exact>
            <MainView />
          </Route>
        </Switch>
      </HashRouter>
    </Provider>
  </ClientProvider>,
  document.getElementById("root")
);
