import { FC } from "react";
import styled from "styled-components";
import { Text } from "../components/Typography";
import { useAppSelector } from "../app/hooks";
import Spinner from "../components/Spinner";
import MapLogo from "../components/MapLogo";

const LoadingPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 40px;
  flex: 1 1 100%;
  height: 100%;
`;

const LoadingPosition: FC = () => {
  const tracking = useAppSelector((state) => state.tracking);
  const statusMessage =
    tracking.errorMessage || tracking.timestamp === null
      ? "Standort wird ermittelt..."
      : "Standort wird übertragen...";
  return (
    <LoadingPositionContainer>
      <Text>{statusMessage}</Text>
      {tracking.error === null ? <Spinner size={80} alt="Laden" /> : null}
      <MapLogo />
    </LoadingPositionContainer>
  );
};

export default LoadingPosition;
