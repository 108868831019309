import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
// import type {
//   Client,
//   ErrorResponse,
//   GeneralFailureEvent,
// } from "@salus/location-client";
import type { Position } from "../features/tracking";
import { config } from "../config";

export enum Errors {
  unknownEmergencyId,
  noNetwork,
}

export enum CallKinds {
  Active,
  Unknown,
  Closed,
}

interface ActiveCall {
  kind: CallKinds.Active;
  psapName: string;
}

interface UnknownCall {
  kind: CallKinds.Unknown;
}

interface ClosedCall {
  kind: CallKinds.Closed;
  psapName: string;
}

export type CallStatus = ActiveCall | UnknownCall | ClosedCall;

interface ClientInterface {
  loading: boolean;
  getCallStatus: () => Promise<CallStatus>;
  connected?: boolean;
  updateLocation: (location: Position) => void;
  errors: Errors[];
}

const servers = config.servers;

export const ClientContext = createContext<ClientInterface | null>(null);

export const ClientProvider = ({ children }) => {
  const [clientLoading, setClientLoading] = useState(true);
  const [errors, setErrors] = useState<Errors[]>([]);
  const [connected, setConnected] = useState<boolean | undefined>(undefined);
  // const client = useRef<Client | null>(null);

  const init = async () => {
    // var Client = require("@salus/citizen-client").Client;
    // console.info("=== initializing client ===", { servers });
    // client.current = new Client({
    //   servers,
    // }, window.location.href);
    // client.current.start()
    setConnected(true);
    setTimeout(() => setClientLoading(false), 2000);
  };

  const getCallStatus = async (): Promise<CallStatus> => {
    return { kind: CallKinds.Active, psapName: "Alarm-Leitstelle!!!!" };
    // return { kind: CallKinds.UnknownCall };
  };

  const updateLocation = (location: Position) => {
    console.log({ location });
  };

  useEffect(() => {
    setTimeout(init, 100);
    // return stop
  }, []);

  const api: ClientInterface = {
    loading: clientLoading,
    getCallStatus,
    connected,
    updateLocation,
    errors,
  };

  return (
    <ClientContext.Provider value={api}>{children}</ClientContext.Provider>
  );
};

export const useClient = () => {
  const client = useContext(ClientContext);
  if (client === null) {
    throw new Error(
      "useClient must be used within a ClientContext.Provider tag"
    );
  }

  return client;
};
