import { useState, FC } from "react";
import {
  Map as LeafletMap,
  Marker,
  Popup,
  TileLayer,
  Viewport,
} from "react-leaflet";
import * as Leaflet from "leaflet";
import MarkerIcon from "leaflet/dist/images/marker-icon.png";
import styled from "styled-components";
import LoadingPosition from "./LoadingPosition";
import { useAppSelector } from "../app/hooks";
import { Text, types, weights } from "../components/Typography";
import { config } from "../config";
import { Secondary, Option } from "../utils/AppColor";
import logo from "../assets/logo.svg";
import "leaflet/dist/leaflet.css";

const Banner = styled.div`
  display: flex;
  min-height: 5%;
  justify-content: center;
  align-items: center;
  background-color: ${Option};
  padding: 10px;
`;

const LocationAccuracy = styled.div`
  display: flex;
  flex: 1 0 100%;
  justify-content: center;
  position: absolute;
  bottom: 8px;
  width: 100%;
  z-index: 999;
`;

const Accuracy = styled(Text)`
  padding: 10px;
  border-radius: 20px;
  background-color: ${Secondary};
  white-space: nowrap;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Positioner = styled.div`
  position: relative;
  flex: 1 1 100%;
  height: 100%;
  width: 100%;
  text-align: center;
`;

const Map = styled(LeafletMap)`
  flex: 1 1 100%;
  height: 100%;
  width: 100%;
`;
const locationMarkerIcon = Leaflet.icon({
  iconUrl: MarkerIcon,
  iconSize: [25, 40],
  iconAnchor: [13, 40],
});

export const MapScreen: FC<{
  psapName: string;
  position?: Leaflet.LatLngTuple;
  accuracy: number | null;
}> = ({ psapName, position, accuracy }) => {
  const [viewport, setViewport] = useState<Viewport>({
    zoom: 14,
    center: null,
  });

  return (
    <>
      <Header>
        <img
          src={logo}
          style={{
            marginTop: 19,
            marginBottom: 16,
            maxWidth: 200,
          }}
        />
        <Text>Ihr Standort wird übertragen an:</Text>
        <Text>{psapName}</Text>
      </Header>
      <Positioner>
        {accuracy !== null && (
          <LocationAccuracy>
            <Accuracy type={types.background}>
              {`Standort-Genauigkeit: ${Math.round(accuracy)}m`}
            </Accuracy>
          </LocationAccuracy>
        )}
        <Map
          center={position}
          viewport={viewport}
          onViewportChanged={setViewport}
          zoomControl={false}
          maxZoom={19}
          attributionControl={false}
        >
          <TileLayer
            url={config.osm.tileUrl}
            subdomains={config.osm.tileUrlSubdomains}
          />
          {position && (
            <Marker position={position} icon={locationMarkerIcon}>
              <Popup>Ihr Standort</Popup>
            </Marker>
          )}
        </Map>
      </Positioner>
      <Banner>
        <Text weight={weights.bold} type={types.background}>
          Bitte lassen Sie dieses Fenster geöffnet.
        </Text>
      </Banner>
    </>
  );
};

const MapContainer: FC<{ psapName: string }> = ({ psapName }) => {
  const tracking = useAppSelector((state) => state.tracking);

  if (!tracking) {
    return <LoadingPosition />;
  }

  const accuracy = tracking.accuracy;
  const position: Leaflet.LatLngTuple | undefined =
    tracking.latitude && tracking.longitude
      ? [tracking.latitude, tracking.longitude]
      : undefined;

  return (
    <MapScreen psapName={psapName} accuracy={accuracy} position={position} />
  );
};

export default MapContainer;
