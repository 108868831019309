import React, { FC } from "react";
import mapImage from "../assets/map.svg";

const MapLogo: FC = () => (
  <img
    src={mapImage}
    style={{ marginTop: 34, height: 155, width: 220 }}
    alt="Karte"
  />
);

export default MapLogo;
