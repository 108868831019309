import { FC } from "react";
import styled from "styled-components";
import Button, { types } from "../components/Button";
import { Text } from "../components/Typography";
import { useAppDispatch } from "../app/hooks";

import { start } from "../features/tracking";
import { nextStep } from "../features/wizard";
import logo from "../assets/logo.svg";
import MyLocation from "../assets/my_location.svg";
import MapLogo from "../components/MapLogo";
const StartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 40px;
  height: 100%;
`;

const ConnectScreen: FC<{ psapName: string }> = ({ psapName }) => {
  const dispatch = useAppDispatch();
  const startTransmission = (): void => {
    dispatch(start());
    dispatch(nextStep());
  };

  return (
    <StartContainer>
      <img
        src={logo}
        style={{
          marginTop: 19,
          marginBottom: 16,
          maxWidth: 200,
        }}
      />
      <Text>{psapName} möchte sie orten.</Text>
      <Button
        type={types.option}
        prefix={<img src={MyLocation} alt="Standort" />}
        onClick={startTransmission}
        large
      >
        Live-Standort teilen
      </Button>
      <MapLogo />
    </StartContainer>
  );
};
export default ConnectScreen;
