import { FC } from "react";
import { Text } from "../components/Typography";
import styled from "styled-components";
import logo from "../assets/logo.svg";

const Logo = styled.img`
  margin-top: 19px;
  margin-bottom: 16px;
  max-width: 160px;
`;

const ErrorImage = styled.img`
  max-width: 150px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 47px;
`;

const ErrorText = styled(Text)`
  white-space: pre-line;
`;

const Error: FC<{ message: string; image: string }> = ({ message, image }) => (
  <Container>
    <Logo src={logo} />
    <ErrorText>{message}</ErrorText>
    <ErrorImage src={image} />
  </Container>
);

export default Error;
