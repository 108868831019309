import { FC } from "react";
import Error from "../components/Error";
import noLocation from "../assets/NoLocation.svg";

const LoadingApp: FC = () => (
  <Error
    message={
      "Die Anfrage ist nicht oder nicht mehr gültig.\n\nIhr Standort wird nicht geteilt."
    }
    image={noLocation}
  />
);

export default LoadingApp;
