import { useEffect } from "react";
import { config } from "../config";
import { useAppDispatch } from "../app/hooks";
import {
  updateError,
  updatePosition,
  TrackingErrorType,
  Position,
} from "../features/tracking";
import { useClient } from "../context/Client";

const options = config.geoTracker;

const useGeoTracker = (): void => {
  const dispatch = useAppDispatch();
  const { updateLocation } = useClient();
  // Potential workarounds for issues on some Android devices:
  // https://tech.namshi.io/blog/2016/11/13/browser-geolocation-the-good-the-bad-and-the-ugly/
  const subscribeToGeotracking = () => {
    if (!navigator.geolocation) {
      dispatch(updateError({ error: TrackingErrorType.UnsupportedDevice }));
      return;
    }

    console.log("GeoTracker start, options:", options);

    let watchId: number | undefined = undefined;

    const stopWatch = () => {
      console.log("GeoTracker stop");
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };

    watchId = navigator.geolocation.watchPosition(
      ({ coords: c, timestamp }) => {
        const coords: Position["coords"] = {
          latitude: c.latitude,
          longitude: c.longitude,
          accuracy: c.accuracy,
          speed: c.speed,
        };
        console.log("GeoTracker position:", coords);
        updateLocation({ coords, timestamp });
        dispatch(updatePosition({ coords, timestamp }));
      },
      (error) => {
        // NOTE: The watch keeps on watching even when
        // this callback was invoked. It may in fact be
        // called multiple times.

        // TODO: Perhaps show a corresponding message when
        // we couldn't obtain a position until a new one
        // comes in?
        console.log("GeoTracker error:", error);

        if (error.code === TrackingErrorType.PermissionDenied) {
          dispatch(updateError({ error: TrackingErrorType.PermissionDenied }));
          stopWatch();
        }
      },
      options
    );

    return stopWatch;
  };

  useEffect(subscribeToGeotracking, []);
};

export default useGeoTracker;
