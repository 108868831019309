import React, { FC } from "react";
import styled, { css } from "styled-components";
import SpinnerIcon from "../assets/spinner.svg";

interface Props {
  size?: number;
}

export const Spinning = styled.img<Props>`
  ${({ size }) =>
    size &&
    css`
       {
        width: ${size}px;
      }
    `}
  margin-left: 5px;
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  transform: translateZ(0);
  animation: spin 1.1s infinite linear;
`;

const Spinner: FC<{ alt?: string; size?: number }> = ({ alt, size }) => (
  <Spinning src={SpinnerIcon} alt={alt} size={size} aria-busy />
);

export default Spinner;
